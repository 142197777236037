@import "../../style/mixins.less";
@import "../../style/globals.less";

@import url(//fonts.googleapis.com/css?family=Lato:700);

html
{
    height: 100%;
    font-family: Arial;
}

a
{
    text-decoration: none;
}

.preload
{
    display: none;
}

body
{
    margin: 0;
    height: 100%;
    
    > div.left
    {
        position: fixed;
        top: 0;
        left: 0;
        background-color: @adminColor;
        width: 30%;
        min-width: 330px;
        height: 100%;  
        text-align: center;

        div.logo
        {
            margin: 50px 0 100px 0;
        }
    
        h1
        {
            font-family: 'Lato', sans-serif;
            color: #fff;
        }
        
        div.separator
        {
            height: 1px;
            width: 200px;
            background-color: @adminColorLight;
            margin: 0 auto 50px auto;
        }
        
        p
        {
            color: @adminColorLight;
            font-family: 'Lato', sans-serif;
            font-size: 11pt;
        }
        
        a.xperto
        {
            background-image: url(/assets/admin/img/xperto-logo.png);
            width: 281px;
            height: 77px;
            display: block;
            margin: 0 auto;
        }
        
        a.xperto:hover
        {           
            background-image: url(/assets/admin/img/xperto-logo-hover.png);
        }
        
        a.impact
        {
            background-image: url(/assets/admin/img/impact-logo.png);
            width: 177px;
            height: 117px;
            display: block;
            margin: 50px auto 0 auto;
        }
                
        a.impact:hover
        {           
            background-image: url(/assets/admin/img/impact-logo-hover.png);
        }     
        
        a.vdv
        {
            background-image: url(/assets/admin/img/vdv-logo.png);
            width: 168px;
            height: 113px;
            display: block;
            margin: 50px auto 0 auto;
        }         
                
        a.vdv:hover
        {           
            background-image: url(/assets/admin/img/vdv-logo-hover.png);
        }         
        
        div.footer
        {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;    
            
            p
            {
                color: #d7d7d7;
                font-size: 10pt;
            
                a
                {
                    color: #fff;
                }
            }
        }
    }
    
    > div.container
    {
        position: fixed;
        left: 30%; 
        height: 100%;    
        float: left;
        .box-shadow(-1px; 0; 1px; rgba(0, 0, 0, 0.4));
        
        div.form
        {
            margin: 11em 0 0 1.5em;
        }
        
        div.error
        {
            color: @errorColor;
            font-size: 11pt;
        }
        
        div.notice
        {
            font-size: 11pt;
        }
        
        h2
        {
            color: #585858;
            font-weight: normal;
        }
        
        input[type=text], input[type=password]
        {
            padding: 10px;
            width: auto;
            min-width: 350px;
            border: 1px solid #ccc;
            .border-radius(3px);
            background-color: #fbffcb;
        }
        
        a
        {
            color: @adminColorLight;
            font-size: 10pt;
            display: block;
            margin: 5px 0 30px 0;
        }
        
        input[type=submit]
        {
            padding: 10px;
            width: auto;
            min-width: 70px;
            border: 1px solid #ccc;
            .border-radius(5px);     
            background-color: @adminColor;
            color: #fff;       
            font-weight: bold;
            cursor: pointer;
            
            &:hover
            {
                background-color: @adminColorHover;            
            }
        }
        
        label
        {
            font-size: 10pt;
            color: #666;
        }
    }
}
 
@media all and (max-width: 1100px)
{
    body > div.container
    {
        left: 330px;
    }
}